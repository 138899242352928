export const formatDateTime = (apiDateTime) => {
    const date = new Date(apiDateTime);
    const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    const formattedDate = date.toLocaleDateString([], { day: '2-digit', month: 'short', year: 'numeric' });
    return `${time}\t${formattedDate}`;
};
export const convertGBToBytes = (gb) => {
    const bytesInOneGB = 1024 * 1024 * 1024;
    return gb * bytesInOneGB;
};

export const formatFileSize = (bytes) => {
    if (bytes < 1024) {
        return bytes + ' Bytes';
    } else if (bytes < 1048576) {
        return (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes < 1073741824) {
        return (bytes / 1048576).toFixed(2) + ' MB';
    } else {
        return (bytes / 1073741824).toFixed(2) + ' GB';
    }
};

export const ellipsifyString = (str, num) => {
    if (!str) return "";
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num-3) + "...";
};

export const normalizeString = (str) => str.replace(/[^\w]/g, '_').toLowerCase();

export const toGigaByte = size => size / (1024 * 1024 * 1024);

export const clampValues = (dataString) => {
    // Split the data into rows
    const rows = dataString.trim().split('\n');

    // Extract the header and data rows
    const header = rows[0];
    const dataRows = rows.slice(1);

    // Process each row
    const processedRows = dataRows.map(row => {
        const [rowValue, colValue, val] = row.split(',');

        // Convert the val to a float, clamp it to [-1, 1], and format it back to a string
        let clampedVal = parseFloat(val);
        if (clampedVal > 1) {
            clampedVal = 1;
        } else if (clampedVal < -1) {
            clampedVal = -1;
        }

        // Return the row in the same format
        return `${rowValue},${colValue},${clampedVal}`;
    });

    // Combine the header with the processed rows
    return [header, ...processedRows].join('\n');
}