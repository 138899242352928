import {createAction, createAsyncThunk, createSlice, isAnyOf} from "@reduxjs/toolkit";
import axios from "axios";
import axiosWithAuth from "../shared/utils/axiosWithAuth";
import {clampValues} from "../shared/utils/DataConverter";

const initialState = {
    loading: false,
    changed: false,
    welcomeContent: '',
    sponsorIntroContent: '',
    sample: [],
    quota: null,
    project: {
        project: [],
        currProject: null,
    },
    job: [],
    currJob: null,
    pipeline: [],
    results: [],
    steps: [],
    sampleUploads: [],
    uploadStatus: false,
    plots: {
        plot1: {loading: false, choice: 'phylum', content: []},
        plot2a: {loading: false, content: []},
        plot2b: {loading: false, content: []},
        plot3: {loading: false, choice: 'phylum', content: []},
        plot4: {loading: false, content: []},
        plot5: {loading: false, choice: 'phylum', content: []},
        plot6: {loading: false, content: []},
        plot7a: {loading: false, content: []},
        plot7b: {loading: false, content: []},
        plot8: {loading: false, content: []},
        plot9: {loading: false, content: []},
        arg_anomaly: {loading: false, content: []},
        arg_normal: {loading: false, content: []}
    },
    success: '',
    error: '',
}

export const getProjects = createAsyncThunk('getProjects', (apiBaseUrl) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/project/projects`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const createProject = createAsyncThunk('createProject', ({apiBaseUrl, requestBody}) => {
    return axiosWithAuth.post(
        `${apiBaseUrl}/project/`,
        requestBody,
        {withCredentials: true}
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return 'Project has been created';
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const deleteProject = createAsyncThunk('deleteProject', ({apiBaseUrl, projectId}) => {
    return axiosWithAuth.delete(
        `${apiBaseUrl}/project/${projectId}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return 'Project has been deleted';
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const getAProject = createAsyncThunk('getAProject', ({apiBaseUrl, projectId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/project/${projectId}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const editProject = createAsyncThunk('editProject', ({apiBaseUrl, projectId, requestBody}) => {
    return axiosWithAuth.put(
        `${apiBaseUrl}/project/${projectId}`,
        requestBody,
        { withCredentials: true },
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return 'Project has been edited';
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const uploadMetaData = createAsyncThunk('uploadMetaData', ({apiBaseUrl, projectId, requestBody}) => {
    return axiosWithAuth.post(
        `${apiBaseUrl}/project/${projectId}/metadata`,
        requestBody,
        {
            withCredentials: true,
            headers: {'Content-Type': 'multipart/form-data'}
        },
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return 'Metadata has been uploaded';
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const downloadMetaData = createAsyncThunk('downloadMetaData', ({apiBaseUrl, projectId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/project/${projectId}/metadata`,
        { responseType: 'blob', withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const getSamples = createAsyncThunk('getSamples', ({apiBaseUrl, projectId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/project/${projectId}/samples`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const createSample = createAsyncThunk('createSample', async ({apiBaseUrl, projectId, projectName, requestBody, index, cancelTokenSource}, {dispatch}) => {
    return axiosWithAuth.post(
        `${apiBaseUrl}/project/${projectId}/sample`,
        requestBody,
        {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                dispatch(setProgress({id: index, progress}));
            },
            cancelToken: cancelTokenSource.token,
        }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return `Sample ${requestBody.get('sample_name')} has been created`;
    }).catch(err => {
        if (axios.isCancel(err))
            throw new Error(err.message);
        throw new Error(err.response?.data.detail);
    });
});

export const deleteSample = createAsyncThunk('deleteSample', ({apiBaseUrl, sampleId}) => {
    return axiosWithAuth.delete(
        `${apiBaseUrl}/project/sample/${sampleId}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return 'Sample has been deleted';
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const getJobs = createAsyncThunk('getJobs', ({apiBaseUrl, projectId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/job/${projectId}/jobs`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    })
});

export const getPipelines = createAsyncThunk('getPipelines', ({apiBaseUrl}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/job/pipelines`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    })
});

export const createJob = createAsyncThunk('createJob', ({apiBaseUrl, requestBody}) => {
    return axiosWithAuth.post(
        `${apiBaseUrl}/job/`,
        requestBody,
        {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return 'Job has been created';
    }).catch(err => {
        throw new Error(err.response.data.detail);
    })
});

export const getAJob = createAsyncThunk('getAJob', ({apiBaseUrl, jobId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/job/${jobId}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    })
});

export const deleteJob = createAsyncThunk('deleteJob', ({apiBaseUrl, jobId}) => {
    return axiosWithAuth.delete(
        `${apiBaseUrl}/job/${jobId}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return 'Job has been deleted'
    }).catch(err => {
        throw new Error(err.response.data.detail);
    })
});

export const downloadJobMetaData = createAsyncThunk('downloadJobMetaData', ({apiBaseUrl, jobId, fileName}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/job/${jobId}/metadata`,
        { responseType: 'blob', withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const getWelcomeContent = createAsyncThunk('getWelcomeContent', ({apiBaseUrl, key}) => {
    return axios.get(
        `${apiBaseUrl}/util/config-item?key=${key}`
    ).then(res => {
        return res.data.value;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const getSponsorIntroContent = createAsyncThunk('getSponsorIntroContent', ({apiBaseUrl, key}) => {
    return axios.get(
        `${apiBaseUrl}/util/config-item?key=${key}`
    ).then(res => {
        return res.data.value;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const getResults = createAsyncThunk('getResults', ({apiBaseUrl, jobId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/job/${jobId}/results`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const downloadResultsArchive = createAsyncThunk('downloadResultsArchive', ({apiBaseUrl, jobId, fileName}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/job/${jobId}/results-archive`,
        { responseType: 'blob', withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const downloadMetadataTemplate = createAsyncThunk('downloadMetadataTemplate', ({apiBaseUrl}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/project/metadata-template`,
        { responseType: 'blob', withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const stopAJob = createAsyncThunk('stopAJob', ({apiBaseUrl, jobId}) => {
    return axiosWithAuth.delete(
        `${apiBaseUrl}/job/${jobId}/stop`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return `Job has been stopped`;
    }).catch(err => {
        throw new Error(err.response.data.message);
    });
});

export const getQuotaUsed = createAsyncThunk('getQuotaUsed', ({apiBaseUrl}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/project/samples/quota-use`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const getSteps = createAsyncThunk('getSteps', ({apiBaseUrl, jobId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/job/${jobId}/pipeline_steps`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const generateVisualization = createAsyncThunk('generateVisualization', ({apiBaseUrl, requestBody}) => {
    return axiosWithAuth.post(
        `${apiBaseUrl}/visualization/generate`,
        requestBody,
        {withCredentials: true}
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return 'Visualization has been created';
    }).catch(err => {
        throw new Error(err.response.data.detail);
    });
});

export const getPlot1Data = createAsyncThunk('getPlot1Data', ({apiBaseUrl, projectId, pipelineId, choice}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/visualization/plot1/${projectId}/${pipelineId}/${choice}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data
    }).catch(err => {
        throw new Error(err.response.data.detail)
    })
});

export const getPlot2aData = createAsyncThunk('getPlot2aData', ({apiBaseUrl, projectId, pipelineId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/visualization/plot2a/${projectId}/${pipelineId}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data
    }).catch(err => {
        throw new Error(err.response.data.detail)
    })
});

export const getPlot2bData = createAsyncThunk('getPlot2bData', ({apiBaseUrl, projectId, pipelineId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/visualization/plot2b/${projectId}/${pipelineId}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data
    }).catch(err => {
        throw new Error(err.response.data.detail)
    })
});

export const getPlot3Data = createAsyncThunk('getPlot3Data', ({apiBaseUrl, projectId, pipelineId, choice}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/visualization/plot3/${projectId}/${pipelineId}/${choice}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data
    }).catch(err => {
        throw new Error(err.response.data.detail)
    })
});

export const getPlot4Data = createAsyncThunk('getPlot4Data', ({apiBaseUrl, projectId, pipelineId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/visualization/plot4/${projectId}/${pipelineId}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data
    }).catch(err => {
        throw new Error(err.response.data.detail)
    })
});

export const getPlot5Data = createAsyncThunk('getPlot5Data', ({apiBaseUrl, projectId, pipelineId, choice}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/visualization/plot5/${projectId}/${pipelineId}/${choice}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data
    }).catch(err => {
        throw new Error(err.response.data.detail)
    })
});

export const getPlot6Data = createAsyncThunk('getPlot6Data', ({apiBaseUrl, projectId, pipelineId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/visualization/plot6/${projectId}/${pipelineId}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data
    }).catch(err => {
        throw new Error(err.response.data.detail)
    })
});

export const getPlot7aData = createAsyncThunk('getPlot7aData', ({apiBaseUrl, projectId, pipelineId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/visualization/plot7a/${projectId}/${pipelineId}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data
    }).catch(err => {
        throw new Error(err.response.data.detail)
    })
});

export const getPlot7bData = createAsyncThunk('getPlot7bData', ({apiBaseUrl, projectId, pipelineId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/visualization/plot7b/${projectId}/${pipelineId}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data
    }).catch(err => {
        throw new Error(err.response.data.detail)
    })
});

export const getPlot8Data = createAsyncThunk('getPlot8Data', ({apiBaseUrl, projectId, pipelineId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/visualization/plot8/${projectId}/${pipelineId}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data
    }).catch(err => {
        throw new Error(err.response.data.detail)
    })
});

export const getPlot9Data = createAsyncThunk('getPlot9Data', ({apiBaseUrl, projectId, pipelineId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/visualization/plot9/${projectId}/${pipelineId}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data
    }).catch(err => {
        throw new Error(err.response.data.detail)
    })
});

export const getArgAnomaly = createAsyncThunk('getArgAnomaly', ({apiBaseUrl, projectId, pipelineId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/visualization/arg_anomaly/${projectId}/${pipelineId}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data
    }).catch(err => {
        throw new Error(err.response.data.detail)
    })
});

export const getArgNormal = createAsyncThunk('getArgNormal', ({apiBaseUrl, projectId, pipelineId}) => {
    return axiosWithAuth.get(
        `${apiBaseUrl}/visualization/arg_normal/${projectId}/${pipelineId}`,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data
    }).catch(err => {
        throw new Error(err.response.data.detail)
    })
});


export const resetService = createAction('resetProject');

export const resetSnackBar = createAction('resetSnackBar');

export const setError = createAction("setError");

export const setSuccess = createAction("setSuccess");

export const setProgress = createAction('setProgress');

export const checkSumSample = createAction('checkSumSample');

export const stopUploadSample = createAction('stopUploadSample');

export const resetUploadStatusBox = createAction('resetUploadStatusBox');

const allServiceSlice = createSlice({
    name: 'allService',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSamples.fulfilled, (state, action) => {
            state.loading = false;
            state.error = '';
            state.sample = action.payload.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0;
            });
            state.sample.projectId = action.payload['project_id'];
            state.changed = false;
        });
        builder.addCase(getQuotaUsed.fulfilled, (state, action) => {
            state.loading = false;
            state.error = '';
            state.changed = false;
            state.quota = action.payload;
        });
        builder.addCase(getProjects.fulfilled, (state, action) => {
            state.loading = false;
            state.error = '';
            state.changed = false;
            state.project.project = action.payload.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0;
            });
        });
        builder.addCase(getAProject.fulfilled, (state, action) => {
            state.loading = false;
            state.error = '';
            state.changed = false;
            state.project.currProject = action.payload;
        });
        builder.addCase(getJobs.fulfilled, (state, action) => {
            state.loading = false;
            state.error = '';
            state.changed = false;
            state.job = action.payload.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0;
            });
        });
        builder.addCase(getPipelines.fulfilled, (state, action) => {
            state.loading = false;
            state.error = '';
            state.changed = false;
            state.pipeline = action.payload.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0;
            });
        });
        builder.addCase(getSteps.fulfilled, (state, action) => {
            state.loading = false;
            state.error = '';
            state.changed = false;
            state.steps = action.payload.sort((a, b) => a.pipeline_step.ordinal - b.pipeline_step.ordinal);
        });
        builder.addCase(getAJob.fulfilled, (state, action) => {
            state.loading = false;
            state.error = '';
            state.changed = false;
            state.currJob = action.payload;
        });
        builder.addCase(downloadMetaData.fulfilled, (state, action) => {
            state.loading = false;
            state.error = '';
            const file = URL.createObjectURL(action.payload);
            const a = document.createElement('a');
            a.href = file;
            a.click();
        });
        builder.addCase(downloadJobMetaData.fulfilled, (state, action) => {
            state.loading = false;
            state.error = '';
            const file = URL.createObjectURL(action.payload);
            const a = document.createElement('a');
            a.href = file;
            a.click();
        });
        builder.addCase(downloadResultsArchive.fulfilled, (state, action) => {
            state.loading = false;
            state.error = '';
            const file = URL.createObjectURL(action.payload);
            const a = document.createElement('a');
            a.download = action.meta.arg.fileName;
            a.href = file;
            a.click();
        });
        builder.addCase(downloadMetadataTemplate.fulfilled, (state, action) => {
            state.loading = false;
            state.error = '';
            const file = URL.createObjectURL(action.payload);
            const a = document.createElement('a');
            a.href = file;
            a.click();
        });
        builder.addCase(getResults.fulfilled, (state, action) => {
            state.loading = false;
            state.error = '';
            state.changed = false;
            state.results = action.payload.sort((a, b) => {
                const nameA = a.output_file_name.toLowerCase();
                const nameB = b.output_file_name.toLowerCase();
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0;
            });
        });
        builder.addCase(getWelcomeContent.fulfilled, (state, action) => {
           state.loading = false;
           state.error = '';
           state.welcomeContent = action.payload;
        });
        builder.addCase(getSponsorIntroContent.fulfilled, (state, action) => {
            state.loading = false;
            state.error = '';
            state.sponsorIntroContent = action.payload;
        });
        builder.addCase(resetService, state => {
            state.sample = [];
            state.job = [];
            state.pipeline = [];
            state.project.currProject = null;
            state.metaData = '';
        });
        builder.addCase(resetSnackBar, state => {
            state.success = '';
            state.error = '';
        });
        builder.addCase(setError, (state, action) => {
            state.success = '';
            state.error = action.payload;
        });
        builder.addCase(setSuccess, (state, action) => {
            state.success = action.payload;
            state.error = '';
        });
        builder.addCase(setProgress, (state, action) => {
            state.sampleUploads = state.sampleUploads.map(e =>
                e.id === action.payload.id ? { ...e, progress: action.payload.progress, status: 'uploading' } : e
            );
        });
        builder.addCase(checkSumSample, (state, action) => {
            state.sampleUploads = [
                {
                    name: action.payload.name,
                    progress: 0,
                    cancelTokenSource: action.payload.cancelTokenSource,
                    status: 'checkSum',
                    id: action.payload.id,
                    projectId: action.payload.projectId,
                    projectName: action.payload.projectName
                },
                ...state.sampleUploads
            ];
            state.uploadStatus = true;
        });
        builder.addCase(resetUploadStatusBox, state => {
            state.uploadStatus = false;
        })
        builder.addCase(stopUploadSample, (state, action) => {
            state.sampleUploads = state.sampleUploads.filter(e => {
                if (e.id === action.payload.id) {
                    e.cancelTokenSource.cancel(`Sample ${action.payload.name} has been stopped uploading`);
                    return false;
                }
                return true;
            });
        });
        builder.addCase(createSample.fulfilled, (state, action) => {
            state.loading = false;
            state.changed = true;
            state.error = '';
            state.success = action.payload;
            state.sampleUploads = state.sampleUploads.map(e => {
                if (action.meta.arg.index === e.id)
                    e = {...e, status: 'succeed'}
                return e;
            });
        });
        builder.addCase(createSample.rejected, (state, action) => {
            state.loading = false;
            state.success = '';
            state.error = action.error.message === 'Missing cookie access_token_cookie' ? '' : action.error.message;
            state.sampleUploads = state.sampleUploads.map(e => {
                if (action.meta.arg.index === e.id && e.status !== 'stopped')
                    e = {...e, status: 'failed'}
                return e;
            });
        });
        builder.addCase(getPlot1Data.pending, (state) => {
            state.plots.plot1.loading = true;
        });
        builder.addCase(getPlot1Data.fulfilled, (state, action) => {
            state.plots.plot1.loading = false;
            state.plots.plot1.content = action.payload
        });
        builder.addCase(getPlot1Data.rejected, (state, action) => {
            state.plots.plot1.loading = false;
            state.plots.plot1.content = 'failed to fetch file'
        });
        builder.addCase(getPlot2aData.pending, (state) => {
            state.plots.plot2a.loading = true;
        });
        builder.addCase(getPlot2aData.fulfilled, (state, action) => {
            state.plots.plot2a.loading = false;
            state.plots.plot2a.content = action.payload
        });
        builder.addCase(getPlot2aData.rejected, (state, action) => {
            state.plots.plot2a.loading = false;
            state.plots.plot2a.content = 'failed to fetch file'
        });
        builder.addCase(getPlot2bData.pending, (state) => {
            state.plots.plot2b.loading = true;
        });
        builder.addCase(getPlot2bData.fulfilled, (state, action) => {
            state.plots.plot2b.loading = false;
            state.plots.plot2b.content = action.payload
        });
        builder.addCase(getPlot2bData.rejected, (state, action) => {
            state.plots.plot2b.loading = false;
            state.plots.plot2b.content = 'failed to fetch file'
        });
        builder.addCase(getPlot3Data.pending, (state) => {
            state.plots.plot3.loading = true;
        });
        builder.addCase(getPlot3Data.fulfilled, (state, action) => {
            state.plots.plot3.loading = false;
            state.plots.plot3.content = clampValues(action.payload)
        });
        builder.addCase(getPlot3Data.rejected, (state, action) => {
            state.plots.plot3.loading = false;
            state.plots.plot3.content = 'failed to fetch file'
        });
        builder.addCase(getPlot4Data.pending, (state) => {
            state.plots.plot4.loading = true;
        });
        builder.addCase(getPlot4Data.fulfilled, (state, action) => {
            state.plots.plot4.loading = false;
            state.plots.plot4.content = action.payload
        });
        builder.addCase(getPlot4Data.rejected, (state, action) => {
            state.plots.plot4.loading = false;
            state.plots.plot4.content = 'failed to fetch file'
        });
        builder.addCase(getPlot5Data.pending, (state) => {
            state.plots.plot5.loading = true;
        });
        builder.addCase(getPlot5Data.fulfilled, (state, action) => {
            state.plots.plot5.loading = false;
            state.plots.plot5.content = action.payload
        });
        builder.addCase(getPlot5Data.rejected, (state, action) => {
            state.plots.plot5.loading = false;
            state.plots.plot5.content = 'failed to fetch file'
        });
        builder.addCase(getPlot6Data.pending, (state) => {
            state.plots.plot6.loading = true;
        });
        builder.addCase(getPlot6Data.fulfilled, (state, action) => {
            state.plots.plot6.loading = false;
            state.plots.plot6.content = action.payload
        });
        builder.addCase(getPlot6Data.rejected, (state, action) => {
            state.plots.plot6.loading = false;
            state.plots.plot6.content = 'failed to fetch file'
        });
        builder.addCase(getPlot7aData.pending, (state) => {
            state.plots.plot7a.loading = true;
        });
        builder.addCase(getPlot7aData.fulfilled, (state, action) => {
            state.plots.plot7a.loading = false;
            state.plots.plot7a.content = action.payload
        });
        builder.addCase(getPlot7aData.rejected, (state, action) => {
            state.plots.plot7a.loading = false;
            state.plots.plot7a.content = 'failed to fetch file'
        });
        builder.addCase(getPlot7bData.pending, (state) => {
            state.plots.plot7b.loading = true;
        });
        builder.addCase(getPlot7bData.fulfilled, (state, action) => {
            state.plots.plot7b.loading = false;
            state.plots.plot7b.content = action.payload
        });
        builder.addCase(getPlot7bData.rejected, (state, action) => {
            state.plots.plot7b.loading = false;
            state.plots.plot7b.content = 'failed to fetch file'
        });
        builder.addCase(getPlot8Data.pending, (state) => {
            state.plots.plot8.loading = true;
        });
        builder.addCase(getPlot8Data.fulfilled, (state, action) => {
            state.plots.plot8.loading = false;
            state.plots.plot8.content = action.payload
        });
        builder.addCase(getPlot8Data.rejected, (state, action) => {
            state.plots.plot8.loading = false;
            state.plots.plot8.content = 'failed to fetch file'
        });
        builder.addCase(getPlot9Data.pending, (state) => {
            state.plots.plot9.loading = true;
        });
        builder.addCase(getPlot9Data.fulfilled, (state, action) => {
            state.plots.plot9.loading = false;
            state.plots.plot9.content = action.payload
        });
        builder.addCase(getPlot9Data.rejected, (state, action) => {
            state.plots.plot9.loading = false;
            state.plots.plot9.content = 'failed to fetch file'
        });
        builder.addCase(getArgAnomaly.pending, (state) => {
            state.plots.arg_anomaly.loading = true;
        });
        builder.addCase(getArgAnomaly.fulfilled, (state, action) => {
            state.plots.arg_anomaly.loading = false;
            state.plots.arg_anomaly.content = action.payload
        });
        builder.addCase(getArgAnomaly.rejected, (state, action) => {
            state.plots.arg_anomaly.loading = false;
            state.plots.arg_anomaly.content = 'failed to fetch file'
        });
        builder.addCase(getArgNormal.pending, (state) => {
            state.plots.arg_normal.loading = true;
        });
        builder.addCase(getArgNormal.fulfilled, (state, action) => {
            state.plots.arg_normal.loading = false;
            state.plots.arg_normal.content = action.payload
        });
        builder.addCase(getArgNormal.rejected, (state, action) => {
            state.plots.arg_normal.loading = false;
            state.plots.arg_normal.content = 'failed to fetch file'
        });
        builder.addMatcher(
            isAnyOf(
                getProjects.pending,
                createProject.pending,
                deleteProject.pending,
                getAProject.pending,
                editProject.pending,
                uploadMetaData.pending,
                getSamples.pending,
                deleteSample.pending,
                getJobs.pending,
                getPipelines.pending,
                createJob.pending,
                getAJob.pending,
                deleteJob.pending,
                getWelcomeContent.pending,
                getSponsorIntroContent.pending,
                getResults.pending,
                stopAJob.pending,
                getQuotaUsed.pending,
                getSteps.pending,
                createSample.pending,
                generateVisualization.pending,
            ),
            state => {
                state.loading = true;
            }
        ).addMatcher(
            isAnyOf(
                createProject.fulfilled,
                editProject.fulfilled,
                deleteProject.fulfilled,
                uploadMetaData.fulfilled,
                deleteSample.fulfilled,
                createJob.fulfilled,
                deleteJob.fulfilled,
                stopAJob.fulfilled,
                generateVisualization.fulfilled,
            ),
            (state, action) => {
                state.loading = false;
                state.changed = true;
                state.error = '';
                state.success = action.payload;
            }
        ).addMatcher(
            isAnyOf(
                getProjects.rejected,
                createProject.rejected,
                deleteProject.rejected,
                editProject.rejected,
                uploadMetaData.rejected,
                downloadMetaData.rejected,
                deleteSample.rejected,
                getPipelines.rejected,
                createJob.rejected,
                deleteJob.rejected,
                downloadJobMetaData.rejected,
                getWelcomeContent.rejected,
                getSponsorIntroContent.rejected,
                stopAJob.rejected,
                downloadResultsArchive.rejected,
                getQuotaUsed.rejected,
                downloadMetadataTemplate.rejected,
                generateVisualization.rejected,
            ),
            (state, action) => {
                state.loading = false;
                state.success = '';
                state.error = action.error.message === 'Missing cookie access_token_cookie' ? '' : action.error.message;
            }
        );
    }
});

export default allServiceSlice.reducer;